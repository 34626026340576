<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            :main-title="this.$t('contact.section1.texts.text1-1')"
            :sub-title="this.$t('contact.section1.texts.text1-2')"
            bg-color=""
        />

        <!-- ANCHOR: 문의하기 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <v-row
                    class="d-flex justify-center"
                    no-gutters
                >
                    <v-col class="pt-0 pb-0">
                        <p class="le-text-subtitle2 text-center mb-10">
                            {{ this.$t('contact.section1.texts.text2-1') }}
                        </p>
                    </v-col>
                </v-row>
                
                <v-row class="d-flex justify-center">
                    <v-col
                        lg="8"
                        md="12"
                        sm="12"
                    >
                        <v-row>
                            <v-col class="pb-0 pt-0 mb-2">
                                <p class="le-text-body3 text-red1 text-right mb-3">
                                    {{ this.$t('contact.section1.texts.text2-2') }}
                                </p>
                            </v-col>
                        </v-row>

                        <v-form ref="form">
                            <!-- 문의 유형 -->
                            <v-row>
                                <v-col class="pb-0 pt-0 mb-2">
                                    <p class="contact_title mb-3">
                                        <strong class="le-text-subtitle3">{{ this.$t('contact.section1.texts.text3-1') }}</strong>
                                        <span class="le-text-body2 text-red1 ml-1">*</span>
                                    </p>

                                    <v-radio-group
                                        v-model="type"
                                        :rules="[v => !!v || this.$t('contact.section1.errors.text1-1')]"
                                        row
                                        class="pt-0"
                                    >
                                        <v-radio
                                            :value="this.$t('contact.section1.texts.text3-2')"
                                            :label="this.$t('contact.section1.texts.text3-2')"
                                            :ripple="false"
                                            color="blue1"
                                            off-icon="fontagon-icons ft-icon ft-ic_radioButton_off"
                                            on-icon="fontagon-icons ft-icon ft-ic_radioButton_on"
                                        ></v-radio>
                                        <v-radio
                                            :value="this.$t('contact.section1.texts.text3-3')"
                                            :label="this.$t('contact.section1.texts.text3-3')"
                                            :ripple="false"
                                            color="blue1"
                                            off-icon="fontagon-icons ft-icon ft-ic_radioButton_off"
                                            on-icon="fontagon-icons ft-icon ft-ic_radioButton_on"
                                        ></v-radio>
                                        <v-radio
                                            :value="this.$t('contact.section1.texts.text3-4')"
                                            :label="this.$t('contact.section1.texts.text3-4')"
                                            :ripple="false"
                                            color="blue1"
                                            off-icon="fontagon-icons ft-icon ft-ic_radioButton_off"
                                            on-icon="fontagon-icons ft-icon ft-ic_radioButton_on"
                                        ></v-radio>
                                        <v-radio
                                            :value="this.$t('contact.section1.texts.text3-5')"
                                            :label="this.$t('contact.section1.texts.text3-5')"
                                            :ripple="false"
                                            color="blue1"
                                            off-icon="fontagon-icons ft-icon ft-ic_radioButton_off"
                                            on-icon="fontagon-icons ft-icon ft-ic_radioButton_on"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>

                            <!-- 이름, 회사명 -->
                            <v-row>
                                <v-col
                                    cols="10"
                                    md="6"
                                    sm="9"
                                    class="pb-0 pt-0 mb-2"
                                >
                                    <p class="contact_title mb-3">
                                        <strong class="le-text-subtitle3">{{ this.$t('contact.section1.texts.text4-1') }}</strong>
                                        <span class="le-text-body2 text-red1 ml-1">*</span>
                                    </p>

                                    <v-text-field
                                        v-model="name"
                                        :rules="[v => !!v || this.$t('contact.section1.errors.text2-1')]"
                                        outlined
                                        color="blue1"
                                        :dense="$vuetify.breakpoint.smAndDown"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="10"
                                    md="6"
                                    sm="9"
                                    class="pb-0 pt-0 mb-2"
                                >
                                    <p class="contact_title mb-3">
                                        <strong class="le-text-subtitle3">{{ this.$t('contact.section1.texts.text5-1') }}</strong>
                                        <span class="le-text-body2 text-red1 ml-1">*</span>
                                        <span class="le-text-body2 ml-1">{{ this.$t('contact.section1.texts.text5-2') }}</span>
                                    </p>

                                    <v-text-field
                                        v-model="tel"
                                        :rules="[
                                            v => !!v || this.$t('contact.section1.errors.text3-1'),
                                            v => /^\d+$/.test(v) || this.$t('contact.section1.errors.text3-2')
                                        ]"
                                        outlined
                                        color="blue1"
                                        :dense="$vuetify.breakpoint.smAndDown"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <!-- 이메일, 인지경로 -->
                            <v-row>
                                <v-col
                                    cols="10"
                                    md="6"
                                    sm="9"
                                    class="pb-0 pt-0 mb-2"
                                >
                                    <p class="contact_title mb-3">
                                        <strong class="le-text-subtitle3">{{ this.$t('contact.section1.texts.text6-1') }}</strong>
                                        <span class="le-text-body2 text-red1 ml-1">*</span>
                                    </p>

                                    <v-text-field
                                        v-model="email"
                                        :rules="[
                                            v => !!v || this.$t('contact.section1.errors.text4-1'),
                                            v => /.+@.+\..+/.test(v) || this.$t('contact.section1.errors.text4-2')
                                        ]"
                                        outlined
                                        color="blue1"
                                        placeholder="info@lecos.co.kr"
                                        :dense="$vuetify.breakpoint.smAndDown"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="10"
                                    md="6"
                                    sm="9"
                                    class="pb-0 pt-0 mb-2"
                                >
                                    <p class="contact_title mb-3">
                                        <strong class="le-text-subtitle3">{{ this.$t('contact.section1.texts.text7-1') }}</strong>
                                        <span class="le-text-body2 ml-1">{{ this.$t('contact.section1.texts.text7-2') }}</span>
                                        <span class="le-text-body3 ml-1">
                                            <br v-if="$vuetify.breakpoint.xs">
                                            {{ this.$t('contact.section1.texts.text7-3') }}
                                        </span>
                                    </p>

                                    <v-text-field
                                        v-model="path"
                                        :rules="[v => v.length <= 50 || this.$t('contact.section1.errors.text5-1')]"
                                        outlined
                                        color="blue1"
                                        :dense="$vuetify.breakpoint.smAndDown"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <!-- 문의 사항 -->
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pb-0 pt-0 mb-2"
                                >
                                    <p class="contact_title mb-3">
                                        <strong class="le-text-subtitle3">{{ this.$t('contact.section1.texts.text8-1') }}</strong>
                                        <span class="le-text-body2 text-red1 ml-1">*</span>
                                        <span class="le-text-body2 ml-1">{{ this.$t('contact.section1.texts.text8-2') }}</span>
                                    </p>

                                    <v-textarea
                                        v-model="content"
                                        rows="11"
                                        :rules="[
                                            v => !!v || this.$t('contact.section1.errors.text6-1'),
                                            v => v.length <= 5000 || this.$t('contact.section1.errors.text6-2')
                                        ]"
                                        outlined
                                        color="blue1"
                                        :counter="5000"
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <!-- 개인정보취급방침 동의 -->
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pb-0 pt-0 mb-2"
                                >
                                    <v-checkbox
                                        v-model="agree"
                                        :rules="[v => !!v || this.$t('contact.section1.errors.text7-1')]"
                                        :ripple="false"
                                        class="mt-0 pt-0 le-text-body2"
                                        color="blue1"
                                        off-icon="fontagon-icons ft-icon ft-ic_checkBox_off"
                                        on-icon="fontagon-icons ft-icon ft-ic_checkBox_on"
                                    >
                                        <template v-slot:label>
                                            <RouterLink
                                                :to="{ name: 'PrivacyPage' }"
                                                class="text-blue3 d-flex align-center l-text-button mr-1"
                                            >
                                                <span class="text">{{ text9_1 }}</span>
                                            </RouterLink>{{ text9_2 }}<span class="le-text-body2 text-red1 ml-1">*</span>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>

                <v-row class="mt-10">
                    <v-col class="d-flex align-center justify-center pt-0 pb-0">
                        <v-btn
                            color="orange1"
                            :large="$vuetify.breakpoint.smAndUp"
                            depressed
                            class="text-white1"
                            :class="{ 'disabled': isLoading }"
                            @click="handleSubmit"
                        >
                            {{ this.$t('contact.section1.btns.btn1') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </section>

        <template>
            <v-dialog
                v-model="isInquirySuccessDialogVisible"
                width="588"
            >
                <v-card class="pa-0 rounded-lg">
                    <div class="le-heading6 pa-5">
                        {{ this.$t('contact.section2.texts.text1') }}
                    </div>

                    <div
                        class="le-text-body2 pl-5 pr-6"
                        style="min-height: 88px;"
                        v-html="this.$t('contact.section2.texts.text2')"
                    >
                    </div>

                    <div class="pa-5 d-flex justify-end">
                        <v-btn
                            color="orange1"
                            :small="$vuetify.breakpoint.smAndDown"
                            depressed
                            class="text-white1"
                            @click="handleDialogClose"
                        >
                            {{ this.$t('contact.section2.btns.btn1') }}
                        </v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </template>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { mapActions } from 'vuex';
import { getContactMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'ContactPage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getContactMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            isLoading: false,
            isInquirySuccessDialogVisible: false,
            lang: 'ko-KR',
            type: '',
            name: '',
            tel: '',
            email: '',
            path: '',
            content: '',
            agree: false,
        };
    },

    computed: {
        text9_1() {
            return this.$t('contact.section1.texts.text9-1');
        },
        text9_2() {
            return this.$t('contact.section1.texts.text9-2');
        }
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        ...mapActions(
            'inquery', [ 'sendEmail' ]
        ),

        /* ============================================================
         * ANCHOR: 문의하기 버튼 클릭
         * ============================================================ */
        async handleSubmit() {
            try {
                // 유효성 검사
                if (!this.$refs.form.validate()) {
                    console.log('validate!!');
                    return;
                }

                this.isLoading = true;

                // API 호출
                const response = await this.sendEmail({
                    lang: this.lang,
                    type: this.type,
                    name: this.name,
                    tel: this.tel,
                    email: this.email,
                    path: this.path,
                    content: this.content,
                });

                if (response.data.result === 'Requested') {
                    this.isInquirySuccessDialogVisible = true;
                    this.isLoading = false;
                    console.log('완료!');
                } else {
                    alert(`오류 발생: ${response.data.result}. 다시 시도해 주세요.`);
                    this.isLoading = false;
                }
            } catch (error) {
                this.handleError(error);
            } finally {
                console.log('Request completed');
                this.isLoading = false;
                // 필요에 따라 버튼 비활성화 복원 등
                // this.$refs['btnInquery'].disabled = false;
            }
        },
        handleError(error) {
            // 에러 핸들링 함수
            if (error.response) {
                // 요청이 이루어졌으나 에러 응답을 받은 경우
                console.error('Error Response Data:', error.response.data);
                console.error('Error Response Status:', error.response.status);
                console.error('Error Response Headers:', error.response.headers);
                alert('서버 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.');
            } else if (error.request) {
                // 요청이 이루어졌으나 응답이 없는 경우
                console.error('Error Request:', error.request);
                alert('서버와의 연결에 실패했습니다. 인터넷 연결을 확인해 주세요.');
            } else {
                // 요청 설정 중 오류가 발생한 경우
                console.error('Error Message:', error.message);
                alert('오류가 발생했습니다. 다시 시도해 주세요.');
            }
            console.error('Error Config:', error.config);
            this.isLoading = false;
        },

        /* ============================================================
         * ANCHOR: 확인 버튼 클릭 (문의 완료)
         * ============================================================ */
        handleDialogClose() {
            this.isInquirySuccessDialogVisible = false;
            this.resetForm();
        },
        resetForm() {
            this.type = '';
            this.name = '';
            this.tel = '';
            this.email = '';
            this.path = '';
            this.content = '';
            this.agree = false;

            this.$refs.form.resetValidation();
        },
    },
    // !SECTION: Methods
};
</script>
