<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            :main-title="this.$t('management.section1.texts.text1-1')"
            :sub-title="this.$t('management.section1.texts.text1-2')"
            bg-color="blue12"
        />

        <!-- ANCHOR: 쉽고 빠른 배지 -->
        <section class="c-section bg-blue12 d-flex justify-center">
            <div class="c-contanier">
                <div
                    class="d-flex flex-column step-wrap"
                    :class="{ 'gap-12': $vuetify.breakpoint.mdAndUp, 'gap-10': $vuetify.breakpoint.smAndDown }"
                >
                    <!-- 수여 알림 메일 확인 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center">
                            Step 01
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/management-share-1.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    {{ this.$t('management.section1.texts.text2-1') }}
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        {{ this.$t('management.section1.texts.text2-2') }}
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <!-- 배지 지갑 등록 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center">
                            Step 02
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/management-share-2.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    {{ this.$t('management.section1.texts.text3-1') }}
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        {{ this.$t('management.section1.texts.text3-2') }}
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <!-- 발행 완료 메일 확인 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center">
                            Step 03
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/management-share-3.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    {{ this.$t('management.section1.texts.text4-1') }}
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        {{ this.$t('management.section1.texts.text4-2') }}
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <!-- 수령 배지 확인 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center">
                            Step 04
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/management-share-4.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    {{ this.$t('management.section1.texts.text5-1') }}
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        {{ this.$t('management.section1.texts.text5-2') }}
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 레코스 오픈배지 지갑 기능 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <h2 class="text-center le-heading3 c-title">
                    {{ this.$t('management.section2.texts.text1-1') }}
                </h2>

                <div
                    class="d-flex flex-column mx-auto gap-10"
                    style="max-width: 790px;"
                >
                    <!-- 모으기 -->
                    <v-card
                        class="d-flex"
                        :class="{
                            'pa-10': $vuetify.breakpoint.mdAndUp,
                            'pt-7 pb-7 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                            'pt-8 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <div 
                            class="d-flex align-center justify-center"
                            :class="{
                                'mr-10': $vuetify.breakpoint.mdAndUp,
                                'mr-5': $vuetify.breakpoint.smOnly,
                                'mr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <v-img
                                :src="require('@/assets/img/management-function-1.png')"
                                :width="$vuetify.breakpoint.mdAndUp ? 140 : ($vuetify.breakpoint.smAndDown ? 96 : 96)"
                                :height="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 72 : 72)"
                                alt=""
                            />
                        </div>

                        <div>
                            <p
                                class="le-heading5 text-blue3" 
                                :class="{
                                    'mb-5': $vuetify.breakpoint.mdAndUp,
                                    'mb-3': $vuetify.breakpoint.smAndDown
                                }"
                            >
                                {{ this.$t('management.section2.texts.text2-1') }}
                            </p>

                            <div class="d-flex flex-column gap-3">
                                <p class="le-text-body1">
                                    {{ this.$t('management.section2.texts.text2-2') }}
                                </p>
                            </div>
                        </div>
                    </v-card>

                    <!-- 공개하기 -->
                    <v-card
                        class="d-flex"
                        :class="{
                            'pa-10': $vuetify.breakpoint.mdAndUp,
                            'pt-7 pb-7 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                            'pt-8 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <div 
                            class="d-flex align-center justify-center"
                            :class="{
                                'mr-10': $vuetify.breakpoint.mdAndUp,
                                'mr-5': $vuetify.breakpoint.smOnly,
                                'mr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <v-img
                                :src="require('@/assets/img/management-function-2.png')"
                                :width="$vuetify.breakpoint.mdAndUp ? 140 : ($vuetify.breakpoint.smAndDown ? 96 : 96)"
                                :height="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 72 : 72)"
                                alt=""
                            />
                        </div>

                        <div>
                            <p
                                class="le-heading5 text-blue3" 
                                :class="{
                                    'mb-5': $vuetify.breakpoint.mdAndUp,
                                    'mb-3': $vuetify.breakpoint.smAndDown
                                }"
                            >
                                {{ this.$t('management.section2.texts.text3-1') }}
                            </p>

                            <div class="d-flex flex-column gap-3">
                                <p class="le-text-body1">
                                    {{ this.$t('management.section2.texts.text3-2') }}
                                </p>

                                <p class="le-text-body1">
                                    {{ this.$t('management.section2.texts.text3-3') }}
                                </p>
                            </div>
                        </div>
                    </v-card>

                    <!-- 공유하기 -->
                    <v-card
                        class="d-flex"
                        :class="{
                            'pa-10': $vuetify.breakpoint.mdAndUp,
                            'pt-7 pb-7 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                            'pt-8 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <div 
                            class="d-flex align-center justify-center"
                            :class="{
                                'mr-10': $vuetify.breakpoint.mdAndUp,
                                'mr-5': $vuetify.breakpoint.smOnly,
                                'mr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <v-img
                                :src="require('@/assets/img/management-function-3.png')"
                                :width="$vuetify.breakpoint.mdAndUp ? 140 : ($vuetify.breakpoint.smAndDown ? 96 : 96)"
                                :height="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 72 : 72)"
                                alt=""
                            />
                        </div>

                        <div>
                            <p
                                class="le-heading5 text-blue3" 
                                :class="{
                                    'mb-5': $vuetify.breakpoint.mdAndUp,
                                    'mb-3': $vuetify.breakpoint.smAndDown
                                }"
                            >
                                {{ this.$t('management.section2.texts.text4-1') }}
                            </p>

                            <div class="d-flex flex-column gap-3">
                                <p class="le-text-body1">
                                    {{ this.$t('management.section2.texts.text4-2') }}
                                </p>
                            </div>
                        </div>
                    </v-card>
                </div>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getManagementMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'ManagementPage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getManagementMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
