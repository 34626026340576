var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-page"},[_c('PageTitle',{attrs:{"main-title":this.$t('management.section1.texts.text1-1'),"sub-title":this.$t('management.section1.texts.text1-2'),"bg-color":"blue12"}}),_c('section',{staticClass:"c-section bg-blue12 d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('div',{staticClass:"d-flex flex-column step-wrap",class:{ 'gap-12': _vm.$vuetify.breakpoint.mdAndUp, 'gap-10': _vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 01 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-share-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" "+_vm._s(this.$t('management.section1.texts.text2-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('management.section1.texts.text2-2'))+" ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 02 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-share-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" "+_vm._s(this.$t('management.section1.texts.text3-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('management.section1.texts.text3-2'))+" ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 03 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-share-3.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" "+_vm._s(this.$t('management.section1.texts.text4-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('management.section1.texts.text4-2'))+" ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 04 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-share-4.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" "+_vm._s(this.$t('management.section1.texts.text5-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('management.section1.texts.text5-2'))+" ")])])])])],1)])])]),_c('section',{staticClass:"c-section d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('h2',{staticClass:"text-center le-heading3 c-title"},[_vm._v(" "+_vm._s(this.$t('management.section2.texts.text1-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column mx-auto gap-10",staticStyle:{"max-width":"790px"}},[_c('v-card',{staticClass:"d-flex",class:{
                        'pa-10': _vm.$vuetify.breakpoint.mdAndUp,
                        'pt-7 pb-7 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                        'pt-8 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly
                    }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                            'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'mr-5': _vm.$vuetify.breakpoint.smOnly,
                            'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-function-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 140 : (_vm.$vuetify.breakpoint.smAndDown ? 96 : 96),"height":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 72 : 72),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{
                                'mb-5': _vm.$vuetify.breakpoint.mdAndUp,
                                'mb-3': _vm.$vuetify.breakpoint.smAndDown
                            }},[_vm._v(" "+_vm._s(this.$t('management.section2.texts.text2-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('management.section2.texts.text2-2'))+" ")])])])]),_c('v-card',{staticClass:"d-flex",class:{
                        'pa-10': _vm.$vuetify.breakpoint.mdAndUp,
                        'pt-7 pb-7 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                        'pt-8 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly
                    }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                            'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'mr-5': _vm.$vuetify.breakpoint.smOnly,
                            'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-function-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 140 : (_vm.$vuetify.breakpoint.smAndDown ? 96 : 96),"height":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 72 : 72),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{
                                'mb-5': _vm.$vuetify.breakpoint.mdAndUp,
                                'mb-3': _vm.$vuetify.breakpoint.smAndDown
                            }},[_vm._v(" "+_vm._s(this.$t('management.section2.texts.text3-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('management.section2.texts.text3-2'))+" ")]),_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('management.section2.texts.text3-3'))+" ")])])])]),_c('v-card',{staticClass:"d-flex",class:{
                        'pa-10': _vm.$vuetify.breakpoint.mdAndUp,
                        'pt-7 pb-7 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                        'pt-8 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly
                    }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                            'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'mr-5': _vm.$vuetify.breakpoint.smOnly,
                            'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-function-3.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 140 : (_vm.$vuetify.breakpoint.smAndDown ? 96 : 96),"height":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 72 : 72),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{
                                'mb-5': _vm.$vuetify.breakpoint.mdAndUp,
                                'mb-3': _vm.$vuetify.breakpoint.smAndDown
                            }},[_vm._v(" "+_vm._s(this.$t('management.section2.texts.text4-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('management.section2.texts.text4-2'))+" ")])])])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }