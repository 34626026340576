<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            :main-title="this.$t('education.section1.texts.text1-1')"
            :sub-title="this.$t('education.section1.texts.text1-2')"
            bg-color="blue9"
        />

        <!-- ANCHOR: 로드 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <div class="road-wrap road-bg road-edu">
                    <div class="road-bg__left road-bg__left--edu"></div>
                    <div class="road-bg__center road-bg__center--edu"></div>
                    <div class="road-bg__right road-bg__right--edu"></div>
    
                    <div class="road-content">
                        <!-- road row 1 -->
                        <div class="road-row road-row-1">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '-1px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/edu-road-1.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 329 : ($vuetify.breakpoint.smOnly ? 213 : 159)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--left">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            {{ this.$t('education.section1.texts.text2-1') }}
                                        </div>
                                    
                                        <div class="le-heading5">
                                            {{ this.$t('education.section1.texts.text2-2') }}
                                        </div>

                                        <p
                                            class="le-text-body2 mt-5"
                                            v-html="this.$t('education.section1.texts.text2-3')"
                                        >
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- road row 2 -->
                        <div class="road-row road-row-2">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '-5px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/edu-road-2.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 271 : ($vuetify.breakpoint.smOnly ? 172 : 135)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--right">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            {{ this.$t('education.section1.texts.text3-1') }}
                                        </div>
                                    
                                        <div class="le-heading5">
                                            {{ this.$t('education.section1.texts.text3-2') }}
                                        </div>

                                        <p
                                            class="le-text-body2 mt-5"
                                            v-html="this.$t('education.section1.texts.text3-3')"
                                        >
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- road row 3 -->
                        <div class="road-row road-row-3">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '-3px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/edu-road-3.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 256 : ($vuetify.breakpoint.smOnly ? 166 : 132)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--left">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            {{ this.$t('education.section1.texts.text4-1') }}
                                        </div>
                                    
                                        <div class="le-heading5">
                                            {{ this.$t('education.section1.texts.text4-2') }}
                                        </div>

                                        <p
                                            class="le-text-body2 mt-5"
                                            v-html="this.$t('education.section1.texts.text4-3')"
                                        >
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- road row 4 -->
                        <div class="road-row road-row-4">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '-26px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/edu-road-4.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 309 : ($vuetify.breakpoint.smOnly ? 204 : 161)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--right">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            {{ this.$t('education.section1.texts.text5-1') }}
                                        </div>
                                    
                                        <div class="le-heading5">
                                            {{ this.$t('education.section1.texts.text5-2') }}
                                        </div>

                                        <p
                                            class="le-text-body2 mt-5"
                                            v-html="this.$t('education.section1.texts.text5-3')"
                                        >
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 선택한 -->
        <section class="c-section d-flex flex-column justify-center pb-0">
            <div
                class="c-contanier"
                :class="{' mx-auto w-100': $vuetify.breakpoint.lgAndUp}"
            >
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 pb-5">
                        {{ this.$t('education.section2.texts.text1-1') }}
                    </h2>
                </div>

                <div
                    class="d-flex flex-column"
                    :class="{ 'gap-10': $vuetify.breakpoint.smAndUp }"
                >
                    <v-row
                        class="d-flex align-center justify-center"
                    >
                        <v-col
                            cols="12"
                            lg="10"
                            md="11"
                            sm="12"
                            class="pa-0"
                            :class="{
                                'd-flex justify-space-between': $vuetify.breakpoint.smAndUp,
                                'd-flex flex-column align-center justify-center': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <!-- 연세대학교 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/edu-institution-1.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center">
                                        {{ this.$t('education.section2.texts.text2-1') }}
                                    </p>

                                    <p
                                        class="le-text-body2 mt-5 text-center"
                                        v-html="this.$t('education.section2.texts.text2-2')"
                                    >
                                    </p>
                                </v-card>
                            </v-col>

                            <!-- 성균관대학교 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/edu-institution-2.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center">
                                        {{ this.$t('education.section2.texts.text3-1') }}
                                    </p>

                                    <p
                                        class="le-text-body2 mt-5 text-center"
                                        v-html="this.$t('education.section2.texts.text3-2')"
                                    >
                                    </p>
                                </v-card>
                            </v-col>

                            <!-- 이화여자대학교 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/edu-institution-3.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center">
                                        {{ this.$t('education.section2.texts.text4-1') }}
                                    </p>

                                    <p
                                        class="le-text-body2 mt-5 text-center"
                                        v-html="this.$t('education.section2.texts.text4-2')"
                                    >
                                    </p>
                                </v-card>
                            </v-col>
                        </v-col>
                    </v-row>

                    <v-row
                        class="d-flex align-center justify-center"
                    >
                        <v-col
                            cols="12"
                            lg="10"
                            md="11"
                            sm="12"
                            class="pa-0"
                            :class="{
                                'd-flex justify-space-between': $vuetify.breakpoint.smAndUp,
                                'd-flex flex-column align-center justify-center': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <!-- IC-PBL 공유협력컨소시엄 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/edu-institution-4.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center">
                                        {{ this.$t('education.section2.texts.text5-1') }}
                                    </p>

                                    <p
                                        class="le-text-body2 mt-5 text-center"
                                        v-html="this.$t('education.section2.texts.text5-2')"
                                    >
                                    </p>
                                </v-card>
                            </v-col>

                            <!-- 한양사이버대학교 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/edu-institution-5.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 text-center mt-5">
                                        {{ this.$t('education.section2.texts.text6-1') }}
                                    </p>

                                    <p
                                        class="le-text-body2 mt-5 text-center"
                                        v-html="this.$t('education.section2.texts.text6-2')"
                                    >
                                    </p>
                                </v-card>
                            </v-col>

                            <!-- 직업계고등학교 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/edu-institution-6.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center">
                                        {{ this.$t('education.section2.texts.text7-1') }}
                                    </p>

                                    <p
                                        class="le-text-body2 mt-5 text-center"
                                        v-html="this.$t('education.section2.texts.text7-2')"
                                    >
                                    </p>
                                </v-card>
                            </v-col>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <!-- 블로그 바로가기 -->
            <BannerBlog />
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getEducationMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';
import BannerBlog from '@/components/BannerBlog.vue';

export default {
    name: 'EducationPage',
    components: {
        PageTitle,
        BannerBlog
    },

    metaInfo() {
        return getEducationMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
