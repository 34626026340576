<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            :main-title="this.$t('issuance.section1.texts.text1-1')"
            :sub-title="this.$t('issuance.section1.texts.text1-2')"
            bg-color="blue9"
        />

        <!-- ANCHOR: Step -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <div
                    class="d-flex flex-column step-wrap"
                    :class="{ 'gap-12': $vuetify.breakpoint.mdAndUp, 'gap-10': $vuetify.breakpoint.smAndDown }"
                >
                    <!-- 계약 진행 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 text-white1 d-flex align-center justify-center">
                            Step 01
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/issuance-step-1.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    {{ this.$t('issuance.section1.texts.text2-1') }}
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        {{ this.$t('issuance.section1.texts.text2-2') }}
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <!-- 기본 설정 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 text-white1 d-flex align-center justify-center">
                            Step 02
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/issuance-step-2.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    {{ this.$t('issuance.section1.texts.text3-1') }}
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        {{ this.$t('issuance.section1.texts.text3-2') }}
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <!-- 발행 준비 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 text-white1 d-flex align-center justify-center">
                            Step 03
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/issuance-step-3.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    {{ this.$t('issuance.section1.texts.text4-1') }}
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        {{ this.$t('issuance.section1.texts.text4-2') }}
                                    </p>

                                    <p class="le-text-body1">
                                        {{ this.$t('issuance.section1.texts.text4-3') }}
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <!-- 배지 발행 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 text-white1 d-flex align-center justify-center">
                            Step 04
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/issuance-step-4.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    {{ this.$t('issuance.section1.texts.text5-1') }}
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        {{ this.$t('issuance.section1.texts.text5-2') }}
                                    </p>

                                    <p class="le-text-body1">
                                        {{ this.$t('issuance.section1.texts.text5-3') }}
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 비용 문의 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <h2 class="text-center le-heading3 c-title">
                    {{ $t('issuance.section2.texts.text1-1') }}
                </h2>

                <div class="d-flex justify-center">
                    <v-btn
                        :to="{ name: 'ContactPage' }"
                        color="orange1"
                        depressed
                        :large="$vuetify.breakpoint.mdAndUp"
                        class="text-white1"
                    >
                        {{ $t('issuance.section2.btns.btn1') }}
                    </v-btn>
                </div>

                <div
                    style="max-width: 995px;"
                    class="d-flex justify-center flex-wrap mx-auto"
                    :class="{
                        'gap-12 mt-20': $vuetify.breakpoint.mdAndUp,
                        'gap-10 mt-10': $vuetify.breakpoint.smAndDown
                    }"
                >
                    <v-card
                        color="blue6"
                        :class="{ 'pa-12': $vuetify.breakpoint.mdAndUp, 'pa-9': $vuetify.breakpoint.smOnly, 'pa-7': $vuetify.breakpoint.xsOnly }"
                        :style="{
                            'width': $vuetify.breakpoint.mdAndUp ? '470px' : $vuetify.breakpoint.smOnly ? '380px' : '100%',
                            'max-width': $vuetify.breakpoint.xsOnly ? '360px' : ''
                        }"
                    >
                        <div class="d-flex">
                            <div :class="{ 'mr-10': $vuetify.breakpoint.mdAndUp, 'mr-8': $vuetify.breakpoint.smAndDown }">
                                <p class="le-heading5">
                                    {{ $t('issuance.section2.texts.text2-1') }}
                                </p>

                                <v-img
                                    :src="require('@/assets/img/issuance-cost-1.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 136 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 136 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                    alt=""
                                />
                            </div>

                            <div
                                class="le-text-body1 mt-1"
                                v-html="$t('issuance.section2.texts.text2-2')"
                            >
                            </div>
                        </div>
                    </v-card>

                    <v-card
                        color="orange2"
                        :class="{ 'pa-12': $vuetify.breakpoint.mdAndUp, 'pa-9': $vuetify.breakpoint.smOnly, 'pa-7': $vuetify.breakpoint.xsOnly }"
                        :style="{
                            'width': $vuetify.breakpoint.mdAndUp ? '470px' : $vuetify.breakpoint.smOnly ? '380px' : '100%',
                            'max-width': $vuetify.breakpoint.xsOnly ? '360px' : ''
                        }"
                    >
                        <div class="d-flex">
                            <div :class="{ 'mr-10': $vuetify.breakpoint.mdAndUp, 'mr-8': $vuetify.breakpoint.smAndDown }">
                                <p class="le-heading5 white-space-nowrap">
                                    {{ $t('issuance.section2.texts.text3-1') }}
                                </p>

                                <v-img
                                    :src="require('@/assets/img/issuance-cost-2.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 136 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 136 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                    alt=""
                                />
                            </div>

                            <div
                                class="le-text-body1 mt-1"
                                v-html="$t('issuance.section2.texts.text3-2')"
                            >
                            </div>
                        </div>
                    </v-card>
                </div>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getIssuanceMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'IssuancePage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getIssuanceMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
