<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            :main-title="this.$t('about.section1.texts.text1-1')"
            :sub-title="this.$t('about.section1.texts.text1-2')"
            bg-color="blue9"
        />

        <!-- ANCHOR: 회사소개 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <v-row
                    class="justify-end align-center"
                >
                    <v-col
                        cols="12"
                        lg="6"
                        md="5"
                        class="d-flex"
                        :class="{
                            'justify-end': $vuetify.breakpoint.lgAndUp,
                            'justify-center': $vuetify.breakpoint.mdAndDown,
                        }"
                    >
                        <v-img
                            :src="require('@/assets/img/about-company-1.png')"
                            class="v-img-w-full"
                            max-width="519"
                            alt=""
                        ></v-img>
                    </v-col>

                    <v-col
                        v-if="$vuetify.breakpoint.mdAndUp"
                        cols="12"
                        md="1"
                    >
                    </v-col>

                    <v-col
                        cols="12"
                        lg="5"
                        md="6"
                        :class="{
                            'mt-15': $vuetify.breakpoint.smAndDown,
                        }"
                    >
                        <p class="le-text-body1">
                            {{ this.$t('about.section1.texts.text2-1') }}
                        </p>
                        <br>
                        <p class="le-text-body1">
                            {{ this.$t('about.section1.texts.text2-2') }}
                        </p>
                        <br>
                        <p class="le-text-body1">
                            {{ this.$t('about.section1.texts.text2-3') }}
                        </p>
                        <br>
                        <p class="le-text-body1">
                            {{ this.$t('about.section1.texts.text2-4') }}
                        </p>
                        <br>
                        <p class="le-heading6">
                            {{ this.$t('about.section1.texts.text2-5') }}
                        </p>
                    </v-col>
                </v-row>
            </div>
        </section>

        <!-- ANCHOR: 찾아오시는 길 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <h2 class="text-center le-heading3 c-title">
                    {{ this.$t('about.section2.texts.text1-1') }}
                </h2>

                <v-row class="justify-center">
                    <v-col
                        cols="12"
                        lg="8"
                        md="10"
                    >
                        <div
                            id="map"
                            style="width: 100%; height: 500px;"
                        ></div>

                        <div
                            class="d-flex flex-column"
                            :class="{
                                'gap-y-10': $vuetify.breakpoint.mdAndUp,
                                'gap-y-9': $vuetify.breakpoint.smOnly,
                                'mt-13': $vuetify.breakpoint.smAndUp,
                                'mt-10 gap-y-6': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div class="d-flex">
                                <div class="mr-5">
                                    <v-img
                                        :src="require('@/assets/img/about-map-1.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 48 : 40)"
                                        alt=""
                                    ></v-img>
                                </div>

                                <div class="pt-2">
                                    <p class="le-heading6">
                                        {{ this.$t('about.section2.texts.text2-1') }}
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="mr-5">
                                    <v-img
                                        :src="require('@/assets/img/about-map-2.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 48 : 40)"
                                        alt=""
                                    ></v-img>
                                </div>

                                <div class="pt-2">
                                    <p class="le-heading6">
                                        {{ this.$t('about.section2.texts.text3-1') }}
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="mr-5">
                                    <v-img
                                        :src="require('@/assets/img/about-map-3.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 48 : 40)"
                                        alt=""
                                    ></v-img>
                                </div>

                                <div class="pt-2">
                                    <p class="le-heading6">
                                        {{ this.$t('about.section2.texts.text4-1') }}
                                    </p>

                                    <p class="mt-3 le-text-body2">
                                        {{ this.$t('about.section2.texts.text4-2') }}
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="mr-5">
                                    <v-img
                                        :src="require('@/assets/img/about-map-4.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 48 : 40)"
                                        alt=""
                                    ></v-img>
                                </div>

                                <div class="pt-2">
                                    <p class="le-heading6">
                                        {{ this.$t('about.section2.texts.text5-1') }}
                                    </p>

                                    <ul class="mt-3 le-text-body2 list-type-1">
                                        <li>{{ this.$t('about.section2.texts.text5-2') }}</li>
                                        <li>{{ this.$t('about.section2.texts.text5-3') }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getAboutMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'AboutPage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getAboutMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            map: null,
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        // 클라이언트 사이드에서만 실행
        if (process.browser) {
            this.initKakaoMap();
        }
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        // api 불러오기
        initKakaoMap() {
            // 카카오 지도 API 스크립트 로드
            const script = document.createElement('script');
            script.src = '//dapi.kakao.com/v2/maps/sdk.js?appkey=15c0b3f3a98e2438cf24949430861698&autoload=false';
            script.onload = () => {
                window.kakao.maps.load(this.initMap);
            };
            document.head.appendChild(script);
        },

        initMap() {
            const container = document.getElementById('map');
            const options = {
                center: new window.kakao.maps.LatLng(37.54268932651642, 126.95058561871812),
                level: 3
            };
            this.map = new window.kakao.maps.Map(container, options);
            this.addMarker();
        },

        addMarker() {
            const markerPosition = new window.kakao.maps.LatLng(37.54268932651642, 126.95058561871812);
            const marker = new window.kakao.maps.Marker({
                position: markerPosition,
            });
            marker.setMap(this.map);
        }
    },
    // !SECTION: Methods
};
</script>
