<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            :main-title="this.$t('openbadge.section1.texts.text1-1')"
            :sub-title="this.$t('openbadge.section1.texts.text1-2')"
            bg-color="blue9"
        />

        <!-- ANCHOR: 인트로 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <v-row>
                    <v-col
                        cols="12"
                        lg="8"
                        md="9"
                        sm="12"
                        class="mx-auto d-flex flex-column"
                        :class="{
                            'gap-y-15': $vuetify.breakpoint.smAndUp,
                            'gap-y-10': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <!-- 인증을 업그레이드 -->
                        <div class="d-flex openbadge-intro-box">
                            <div class="position-absolute openbadge-intro-box__img">
                                <v-img
                                    :src="require('@/assets/img/openbadge-intro-1.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 320 : ($vuetify.breakpoint.smOnly ? 212 : 120)"
                                    contain
                                    alt=""
                                />
                            </div>

                            <div class="d-flex align-center w-100 openbadge-intro-box__content-wrap">
                                <v-card class="d-flex flex-column w-100 openbadge-intro-box__item">
                                    <div class="le-heading6 text-blue3">
                                        {{ $t('openbadge.section1.texts.text2-1') }}
                                    </div>
                                    
                                    <div class="le-heading5">
                                        {{ $t('openbadge.section1.texts.text2-2') }}
                                    </div>

                                    <p
                                        class="le-text-body2 mt-5"
                                        v-html="$t('openbadge.section1.texts.text2-3')"
                                    >
                                    </p>
                                </v-card>
                            </div>
                        </div>

                        <!-- 국제 표준 인증 -->
                        <div class="d-flex openbadge-intro-box odd">
                            <div class="position-absolute openbadge-intro-box__img">
                                <v-img
                                    :src="require('@/assets/img/openbadge-intro-2.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 320 : ($vuetify.breakpoint.smOnly ? 212 : 120)"
                                    contain
                                    alt=""
                                />
                            </div>

                            <div class="d-flex align-center w-100 openbadge-intro-box__content-wrap">
                                <v-card class="d-flex flex-column w-100 pr-10 openbadge-intro-box__item">
                                    <div class="le-heading6 text-blue3">
                                        {{ $t('openbadge.section1.texts.text3-1') }}
                                    </div>
                                    
                                    <div class="le-heading5">
                                        {{ $t('openbadge.section1.texts.text3-2') }}
                                    </div>

                                    <p
                                        class="le-text-body2 mt-5"
                                        v-html="$t('openbadge.section1.texts.text3-3')"
                                    >
                                    </p>

                                    <div
                                        class="mt-5"
                                        :class="{ 'text-center': $vuetify.breakpoint.xsOnly }"
                                    >
                                        <v-btn
                                            color="orange1"
                                            depressed
                                            :small="$vuetify.breakpoint.smAndDown"
                                            class="text-white1"
                                            href="https://site.imsglobal.org/certifications/lecos-inc/lecosopenbadge#cert_pane_nid_473678"
                                            target="_blank"
                                        >
                                            {{ $t('openbadge.section1.btns.btn1') }}
                                        </v-btn>
                                    </div>
                                </v-card>
                            </div>
                        </div>

                        <!-- 검증 및 보안 -->
                        <div class="d-flex openbadge-intro-box">
                            <div class="position-absolute openbadge-intro-box__img">
                                <v-img
                                    :src="require('@/assets/img/openbadge-intro-3.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 320 : ($vuetify.breakpoint.smOnly ? 212 : 120)"
                                    contain
                                    alt=""
                                />
                            </div>

                            <div class="d-flex align-center w-100 openbadge-intro-box__content-wrap">
                                <v-card class="d-flex flex-column w-100 openbadge-intro-box__item">
                                    <div class="le-heading6 text-blue3">
                                        {{ $t('openbadge.section1.texts.text4-1') }}
                                    </div>
                                    
                                    <div class="le-heading5">
                                        {{ $t('openbadge.section1.texts.text4-2') }}
                                    </div>

                                    <p
                                        class="le-text-body2 mt-5"
                                        v-html="$t('openbadge.section1.texts.text4-3')"
                                    >
                                    </p>

                                    <p class="le-text-body3 mt-3">
                                        {{ $t('openbadge.section1.texts.text4-4') }}
                                    </p>
                                </v-card>
                            </div>
                        </div>

                        <!-- 즉시 사용 -->
                        <div class="d-flex openbadge-intro-box odd">
                            <div class="position-absolute openbadge-intro-box__img">
                                <v-img
                                    :src="require('@/assets/img/openbadge-intro-4.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 320 : ($vuetify.breakpoint.smOnly ? 212 : 120)"
                                    contain
                                    alt=""
                                />
                            </div>

                            <div class="d-flex align-center w-100 openbadge-intro-box__content-wrap">
                                <v-card class="d-flex flex-column w-100 pr-10 openbadge-intro-box__item">
                                    <div class="le-heading6 text-blue3">
                                        {{ $t('openbadge.section1.texts.text5-1') }}
                                    </div>
                                    
                                    <div class="le-heading5">
                                        {{ $t('openbadge.section1.texts.text5-2') }}
                                    </div>

                                    <p
                                        class="le-text-body2 mt-5"
                                        v-html="$t('openbadge.section1.texts.text5-3')"
                                    >
                                    </p>
                                </v-card>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </section>

        <!-- ANCHOR: 모든 정보 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 mb-5">
                        {{ $t('openbadge.section2.texts.text1-1') }}
                    </h2>

                    <p
                        class="le-text-subtitle1"
                        v-html="$t('openbadge.section2.texts.text1-2')"
                    >
                    </p>
                </div>

                <div class="d-flex justify-center">
                    <div v-if="this.$route.params.lang == 'kor'">
                        <v-img
                            v-if="$vuetify.breakpoint.smAndUp"
                            :src="require('@/assets/img/openbadge-info-1.png')"
                            :width="$vuetify.breakpoint.mdAndUp ? 634 : ($vuetify.breakpoint.smOnly ? 552 : 328)"
                            contain
                            alt=""
                        />

                        <img
                            v-else
                            :src="require('@/assets/img/openbadge-info-1.png')"
                            alt=""
                        />
                    </div>

                    <div v-if="this.$route.params.lang == 'eng'">
                        <v-img
                            v-if="$vuetify.breakpoint.smAndUp"
                            :src="require('@/assets/img/openbadge-info-1-eng.png')"
                            :width="$vuetify.breakpoint.mdAndUp ? 634 : ($vuetify.breakpoint.smOnly ? 552 : 328)"
                            contain
                            alt=""
                        />

                        <img
                            v-else
                            :src="require('@/assets/img/openbadge-info-1-eng.png')"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 모든 정보 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 mb-5">
                        {{ $t('openbadge.section3.texts.text1-1') }}
                    </h2>

                    <p
                        class="le-text-subtitle1"
                        v-html="$t('openbadge.section3.texts.text1-2')"
                    >
                    </p>
                </div>

                <v-row class="d-flex justify-center">
                    <v-col
                        cols="12"
                        class="d-flex flex-wrap justify-center openbadge-type"
                        :class="{
                            'gap-x-13': $vuetify.breakpoint.mdAndUp,
                            'gap-x-8': $vuetify.breakpoint.smOnly,
                            'flex-column gap-y-9': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <div
                            class="d-flex justify-center"
                            :class="{
                                'gap-x-13': $vuetify.breakpoint.mdAndUp,
                                'gap-x-8': $vuetify.breakpoint.smOnly,
                            }"
                            :style="{ 'column-gap': $vuetify.breakpoint.xsOnly ? '8%' : '' }"
                        >
                            <!-- 수료 -->
                            <div class="openbadge-type__item">
                                <v-card
                                    class="rounded-circle d-flex justify-center align-center pa-0"
                                    :width="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                >
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/openbadge-type-1.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 70 : ($vuetify.breakpoint.smOnly ? 50 : 50)"
                                            contain
                                            alt=""
                                        />
                                    </div>
                                </v-card>

                                <p class="le-heading5 text-center mt-10">
                                    {{ $t('openbadge.section3.texts.text2-1') }}
                                </p>

                                <p
                                    class="le-text-body1 text-center"
                                    :class="{ 
                                        'mt-5': $vuetify.breakpoint.mdAndUp,
                                        'mt-4': $vuetify.breakpoint.smAndDown,
                                    }"
                                >
                                    {{ $t('openbadge.section3.texts.text2-2') }}
                                </p>
                            </div>

                            <!-- 학위 -->
                            <div class="openbadge-type__item">
                                <v-card
                                    class="rounded-circle d-flex justify-center align-center pa-0"
                                    :width="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                >
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/openbadge-type-2.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 70 : ($vuetify.breakpoint.smOnly ? 50 : 50)"
                                            contain
                                            alt=""
                                        />
                                    </div>
                                </v-card>

                                <p class="le-heading5 text-center mt-10">
                                    {{ $t('openbadge.section3.texts.text3-1') }}
                                </p>

                                <p
                                    class="le-text-body1 text-center"
                                    :class="{ 
                                        'mt-5': $vuetify.breakpoint.mdAndUp,
                                        'mt-4': $vuetify.breakpoint.smAndDown,
                                    }"
                                >
                                    {{ $t('openbadge.section3.texts.text3-2') }}
                                </p>
                            </div>

                            <!-- 자격 -->
                            <div class="openbadge-type__item">
                                <v-card
                                    class="rounded-circle d-flex justify-center align-center pa-0"
                                    :width="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                >
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/openbadge-type-3.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 70 : ($vuetify.breakpoint.smOnly ? 50 : 50)"
                                            contain
                                            alt=""
                                        />
                                    </div>
                                </v-card>

                                <p class="le-heading5 text-center mt-10">
                                    {{ $t('openbadge.section3.texts.text4-1') }}
                                </p>

                                <p
                                    class="le-text-body1 text-center"
                                    :class="{ 
                                        'mt-5': $vuetify.breakpoint.mdAndUp,
                                        'mt-4': $vuetify.breakpoint.smAndDown,
                                    }"
                                >
                                    {{ $t('openbadge.section3.texts.text4-2') }}
                                </p>
                            </div>
                        </div>

                        <div
                            class="d-flex justify-center"
                            :class="{
                                'gap-x-13': $vuetify.breakpoint.mdAndUp,
                                'gap-x-8': $vuetify.breakpoint.smOnly,
                            }"
                            :style="{ 'column-gap': $vuetify.breakpoint.xsOnly ? '8%' : '' }"
                        >
                            <!-- 참여 -->
                            <div class="openbadge-type__item">
                                <v-card
                                    class="rounded-circle d-flex justify-center align-center pa-0"
                                    :width="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                >
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/openbadge-type-4.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 70 : ($vuetify.breakpoint.smOnly ? 50 : 50)"
                                            contain
                                            alt=""
                                        />
                                    </div>
                                </v-card>

                                <p class="le-heading5 text-center mt-10">
                                    {{ $t('openbadge.section3.texts.text5-1') }}
                                </p>

                                <p
                                    class="le-text-body1 text-center"
                                    :class="{ 
                                        'mt-5': $vuetify.breakpoint.mdAndUp,
                                        'mt-4': $vuetify.breakpoint.smAndDown,
                                    }"
                                >
                                    {{ $t('openbadge.section3.texts.text5-2') }}
                                </p>
                            </div>

                            <!-- 역량 -->
                            <div class="openbadge-type__item">
                                <v-card
                                    class="rounded-circle d-flex justify-center align-center pa-0"
                                    :width="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                >
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/openbadge-type-5.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 70 : ($vuetify.breakpoint.smOnly ? 50 : 50)"
                                            contain
                                            alt=""
                                        />
                                    </div>
                                </v-card>

                                <p class="le-heading5 text-center mt-10">
                                    {{ $t('openbadge.section3.texts.text6-1') }}
                                </p>

                                <p
                                    class="le-text-body1 text-center"
                                    :class="{ 
                                        'mt-5': $vuetify.breakpoint.mdAndUp,
                                        'mt-4': $vuetify.breakpoint.smAndDown,
                                    }"
                                >
                                    {{ $t('openbadge.section3.texts.text6-2') }}
                                </p>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getOpenBadgesMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'OpenBadgesPage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getOpenBadgesMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
