import ogImageCommon from '../assets/img/og-sns-common.png';
// import ogImageHome from '../assets/img/og-sns-home.png';
// import ogImageOpenbadge from '../assets/img/og-sns-openbadge.png';
// import ogImageIssuance from '../assets/img/og-sns-issuance.png';
// import ogImageManagement from '../assets/img/og-sns-management.png';
// import ogImageSample from '../assets/img/og-sns-sample.png';
// import ogImageGallery from '../assets/img/og-sns-gallery.png';
// import ogImageEducation from '../assets/img/og-sns-education.png';
// import ogImageEnterprise from '../assets/img/og-sns-enterprise.png';
// import ogImageAssociation from '../assets/img/og-sns-association.png';
// import ogImageCompany from '../assets/img/og-sns-company.png';
// import ogImagePress from '../assets/img/og-sns-press.png';
// import ogImageNotice from '../assets/img/og-sns-notice.png';
// import ogImageContact from '../assets/img/og-sns-contact.png';
// import ogImageLogin from '../assets/img/og-sns-login.png';


/* SECTION: Home */
// ANCHOR: Home
export const getHomeMetaInfo = (vm) => ({
    title: vm.$t('meta.home.title'), // vm.$t('meta.home.title')
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.home.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Home */

/* SECTION: Open Badges */
// ANCHOR: Open Badges
export const getOpenBadgesMetaInfo = (vm) => ({
    title: vm.$t('meta.openbadge.openbadge.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.openbadge.openbadge.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.openbadge.openbadge.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.openbadge.openbadge.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Badges Gallery
export const getBadgesGalleryMetaInfo = (vm) => ({
    title: vm.$t('meta.openbadge.badgeGallery.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.openbadge.badgeGallery.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.openbadge.badgeGallery.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.openbadge.badgeGallery.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Open Badges */

/* SECTION: Service */
// ANCHOR: Issuance
export const getIssuanceMetaInfo = (vm) => ({
    title: vm.$t('meta.service.issuance.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.service.issuance.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.service.issuance.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.service.issuance.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Management
export const getManagementMetaInfo = (vm) => ({
    title: vm.$t('meta.service.management.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.service.management.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.service.management.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.service.management.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Sample Badges
export const getSampleBadgesMetaInfo = (vm) => ({
    title: vm.$t('meta.service.sampleBadges.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.service.sampleBadges.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.service.sampleBadges.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.service.sampleBadges.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Service */

/* SECTION: Solutions */
// ANCHOR: Education
export const getEducationMetaInfo = (vm) => ({
    title: vm.$t('meta.solutions.education.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.solutions.education.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.solutions.education.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.solutions.education.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Enterprise
export const getEnterpriseMetaInfo = (vm) => ({
    title: vm.$t('meta.solutions.enterprise.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.solutions.enterprise.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.solutions.enterprise.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.solutions.enterprise.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Association
export const getAssociationMetaInfo = (vm) => ({
    title: vm.$t('meta.solutions.association.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.solutions.association.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.solutions.association.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.solutions.association.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Solutions */

/* SECTION: Company */
// ANCHOR: About
export const getAboutMetaInfo = (vm) => ({
    title: vm.$t('meta.company.about.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.company.about.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.company.about.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.company.about.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Press
export const getPressMetaInfo = (vm) => ({
    title: vm.$t('meta.company.press.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.company.press.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.company.press.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.company.press.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Notice
export const getNoticeMetaInfo = (vm) => ({
    title: vm.$t('meta.company.notice.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.company.notice.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.company.notice.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.company.notice.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Company */

/* SECTION: Login */
// ANCHOR: Login
export const getLoginMetaInfo = (vm) => ({
    title: vm.$t('meta.login.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.login.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.login.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.login.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Login */

/* SECTION: Contact Us */
// ANCHOR: Contact
export const getContactMetaInfo = (vm) => ({
    title: vm.$t('meta.contactUs.title'),
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: vm.$t('meta.contactUs.description'),
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: vm.$t('meta.contactUs.og.title'),
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: vm.$t('meta.contactUs.og.description'),
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Contact Us */