var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-page"},[_c('PageTitle',{attrs:{"main-title":this.$t('about.section1.texts.text1-1'),"sub-title":this.$t('about.section1.texts.text1-2'),"bg-color":"blue9"}}),_c('section',{staticClass:"c-section bg-blue9 d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('v-row',{staticClass:"justify-end align-center"},[_c('v-col',{staticClass:"d-flex",class:{
                        'justify-end': _vm.$vuetify.breakpoint.lgAndUp,
                        'justify-center': _vm.$vuetify.breakpoint.mdAndDown,
                    },attrs:{"cols":"12","lg":"6","md":"5"}},[_c('v-img',{staticClass:"v-img-w-full",attrs:{"src":require('@/assets/img/about-company-1.png'),"max-width":"519","alt":""}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"12","md":"1"}}):_vm._e(),_c('v-col',{class:{
                        'mt-15': _vm.$vuetify.breakpoint.smAndDown,
                    },attrs:{"cols":"12","lg":"5","md":"6"}},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('about.section1.texts.text2-1'))+" ")]),_c('br'),_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('about.section1.texts.text2-2'))+" ")]),_c('br'),_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('about.section1.texts.text2-3'))+" ")]),_c('br'),_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('about.section1.texts.text2-4'))+" ")]),_c('br'),_c('p',{staticClass:"le-heading6"},[_vm._v(" "+_vm._s(this.$t('about.section1.texts.text2-5'))+" ")])])],1)],1)]),_c('section',{staticClass:"c-section d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('h2',{staticClass:"text-center le-heading3 c-title"},[_vm._v(" "+_vm._s(this.$t('about.section2.texts.text1-1'))+" ")]),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","lg":"8","md":"10"}},[_c('div',{staticStyle:{"width":"100%","height":"500px"},attrs:{"id":"map"}}),_c('div',{staticClass:"d-flex flex-column",class:{
                            'gap-y-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'gap-y-9': _vm.$vuetify.breakpoint.smOnly,
                            'mt-13': _vm.$vuetify.breakpoint.smAndUp,
                            'mt-10 gap-y-6': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c('v-img',{attrs:{"src":require('@/assets/img/about-map-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 48 : (_vm.$vuetify.breakpoint.smOnly ? 48 : 40),"alt":""}})],1),_c('div',{staticClass:"pt-2"},[_c('p',{staticClass:"le-heading6"},[_vm._v(" "+_vm._s(this.$t('about.section2.texts.text2-1'))+" ")])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c('v-img',{attrs:{"src":require('@/assets/img/about-map-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 48 : (_vm.$vuetify.breakpoint.smOnly ? 48 : 40),"alt":""}})],1),_c('div',{staticClass:"pt-2"},[_c('p',{staticClass:"le-heading6"},[_vm._v(" "+_vm._s(this.$t('about.section2.texts.text3-1'))+" ")])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c('v-img',{attrs:{"src":require('@/assets/img/about-map-3.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 48 : (_vm.$vuetify.breakpoint.smOnly ? 48 : 40),"alt":""}})],1),_c('div',{staticClass:"pt-2"},[_c('p',{staticClass:"le-heading6"},[_vm._v(" "+_vm._s(this.$t('about.section2.texts.text4-1'))+" ")]),_c('p',{staticClass:"mt-3 le-text-body2"},[_vm._v(" "+_vm._s(this.$t('about.section2.texts.text4-2'))+" ")])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c('v-img',{attrs:{"src":require('@/assets/img/about-map-4.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 48 : (_vm.$vuetify.breakpoint.smOnly ? 48 : 40),"alt":""}})],1),_c('div',{staticClass:"pt-2"},[_c('p',{staticClass:"le-heading6"},[_vm._v(" "+_vm._s(this.$t('about.section2.texts.text5-1'))+" ")]),_c('ul',{staticClass:"mt-3 le-text-body2 list-type-1"},[_c('li',[_vm._v(_vm._s(this.$t('about.section2.texts.text5-2')))]),_c('li',[_vm._v(_vm._s(this.$t('about.section2.texts.text5-3')))])])])])])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }