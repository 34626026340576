<template>
    <div class="lang-menu">
        <button
            class="lang-menu__btn"
            @click="toggleLangMenu"
        >
            <v-icon
                size="24"
                class="mr-2"
                color="blue1"
            >
                fontagon-icons ft-icon ft-ic_globe_outline
            </v-icon>

            <span class="text-gray1 le-text-body3">{{ currentLang }}</span>

            <v-icon
                v-if="isLangMenuOpen"
                size="24"
                color="blue1"
            >
                fontagon-icons ft-icon ft-ic__triangularArrow_up_fill
            </v-icon>
            <v-icon
                v-else
                size="24"
                color="blue1"
            >
                fontagon-icons ft-icon ft-ic__triangularArrow_down_fill
            </v-icon>
        </button>

        <!-- 언어 선택 목록 -->
        <div
            v-if="isLangMenuOpen"
            class="lang-menu__dropdown"
        >
            <ul class="lang-menu__list">
                <li
                    v-for="(lang, index) in availableLanguages"
                    :key="index"
                    class="lang-menu__item"
                    @click="changeLang(lang.value)"
                >
                    {{ lang.label }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LanguageMenu',
    data() {
        return {
            isLangMenuOpen: false, // 언어 설정 메뉴 열림/닫힘 상태
            currentLang: 'KOR', // 현재 언어 표시
            availableLanguages: [
                { label: 'KOR', value: 'kor' },
                { label: 'ENG', value: 'eng' },
            ],
        };
    },
    mounted() {
        this.updateCurrentLang();
    },
    methods: {
        toggleLangMenu() {
            this.isLangMenuOpen = !this.isLangMenuOpen;
        },
        // changeLang(value) {
        //     if (value === 'eng') {
        //         // 영어 선택 시 특정 URL로 이동
        //         window.location.href = 'https://www.lecos.co.kr/eng';
        //     } else {
        //         // 기본 언어 처리
        //         window.location.href = 'https://www.lecos.co.kr/kor';
        //     }
        // },
        changeLang(value) {
            this.$router.push({
                name: this.$route.name,
                params: { lang: value },
            });
            // this.currentLang = value === 'kor' ? 'KOR' : 'ENG';
            this.isLangMenuOpen = false;

            this.$router.go();
        },
        updateCurrentLang() {
            const pathLang = window.location.pathname.split('/')[1];
            if (pathLang === 'eng') {
                this.currentLang = 'ENG';
            } else if (pathLang === 'kor') {
                this.currentLang = 'KOR';
            } else {
                // 기본값 설정
                this.currentLang = 'KOR';
            }
        },
    },
};
</script>