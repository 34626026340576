var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-page"},[_c('PageTitle',{attrs:{"main-title":this.$t('userForOpenbadge.section1.texts.text1-1'),"sub-title":this.$t('userForOpenbadge.section1.texts.text1-2'),"bg-color":""}}),_c('section',{staticClass:"c-section d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",class:{
                        'd-flex align-start justify-space-between': _vm.$vuetify.breakpoint.mdAndUp,
                    },attrs:{"cols":"12","md":"11","lg":"10"}},[_c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"flex":"1 1 50%"},style:({ 'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '384px' : '' })},[_c('p',{staticClass:"le-heading4",class:{
                                'mb-6': _vm.$vuetify.breakpoint.mdAndUp,
                                'mb-5': _vm.$vuetify.breakpoint.smAndDown
                            }},[_vm._v(" "+_vm._s(this.$t('userForOpenbadge.section2.texts.text1-1'))+" ")]),_c('div',[_c('v-img',{attrs:{"src":require('@/assets/img/user-for-openbadge-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 384 : (_vm.$vuetify.breakpoint.smOnly ? 360 : 300),"contain":"","alt":""}})],1),_c('p',{staticClass:"le-text-body1 text-center",class:{
                                'mt-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mt-9': _vm.$vuetify.breakpoint.smOnly,
                                'mt-6': _vm.$vuetify.breakpoint.xsOnly
                            }},[_vm._v(" "+_vm._s(this.$t('userForOpenbadge.section2.texts.text1-2'))+" ")]),_c('div',{staticClass:"d-flex gap-7",class:{
                                'mt-15': _vm.$vuetify.breakpoint.mdAndUp,
                                'mt-12': _vm.$vuetify.breakpoint.smOnly,
                                'mt-10': _vm.$vuetify.breakpoint.xsOnly
                            }},[_c('v-btn',{staticClass:"text-white1",attrs:{"href":"https://nlp.netlearning.co.jp/ns/login/LogIn.aspx?ReturnUrl=/ns/comsys/Default.aspx&lang=en","target":"_blank","color":"orange1","small":_vm.$vuetify.breakpoint.smAndDown,"depressed":""}},[_vm._v(" "+_vm._s(this.$t('userForOpenbadge.section2.btns.btn1'))+" ")]),_c('v-btn',{staticClass:"text-white1",attrs:{"href":"https://lecos.notion.site/b1cbcf01272049f1910984db79e92cbd","target":"_blank","color":"blue1","small":_vm.$vuetify.breakpoint.smAndDown,"depressed":""}},[_vm._v(" "+_vm._s(this.$t('userForOpenbadge.section2.btns.btn2'))+" ")])],1)]),_c('div',{staticClass:"d-flex flex-column align-center justify-center",class:{
                            'mt-22': _vm.$vuetify.breakpoint.smAndDown
                        },staticStyle:{"flex":"1 1 50%"},style:({ 'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '384px' : '' })},[_c('p',{staticClass:"le-heading4",class:{
                                'mb-6': _vm.$vuetify.breakpoint.mdAndUp,
                                'mb-5': _vm.$vuetify.breakpoint.smAndDown
                            }},[_vm._v(" "+_vm._s(this.$t('userForOpenbadge.section2.texts.text2-1'))+" ")]),_c('div',[_c('v-img',{attrs:{"src":require('@/assets/img/user-for-openbadge-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 384 : (_vm.$vuetify.breakpoint.smOnly ? 360 : 300),"alt":""}})],1),_c('p',{staticClass:"le-text-body1 text-center",class:{
                                'mt-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mt-9': _vm.$vuetify.breakpoint.smOnly,
                                'mt-6': _vm.$vuetify.breakpoint.xsOnly
                            }},[_vm._v(" "+_vm._s(this.$t('userForOpenbadge.section2.texts.text2-2'))+" ")]),_c('div',{staticClass:"d-flex gap-7",class:{
                                'mt-15': _vm.$vuetify.breakpoint.mdAndUp,
                                'mt-12': _vm.$vuetify.breakpoint.smOnly,
                                'mt-10': _vm.$vuetify.breakpoint.xsOnly
                            }},[_c('v-btn',{staticClass:"text-white1",attrs:{"href":"https://www.openbadge-global.com/ns/portal/openbadge/login","target":"_blank","color":"orange1","small":_vm.$vuetify.breakpoint.smAndDown,"depressed":""}},[_vm._v(" "+_vm._s(this.$t('userForOpenbadge.section2.btns.btn1'))+" ")]),_c('v-btn',{staticClass:"text-white1",attrs:{"href":"https://lecos.notion.site/8755f872723b4027a1e678a1de3961a5","target":"_blank","color":"blue1","small":_vm.$vuetify.breakpoint.smAndDown,"depressed":""}},[_vm._v(" "+_vm._s(this.$t('userForOpenbadge.section2.btns.btn3'))+" ")])],1)])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }