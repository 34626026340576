<template>
    <!-- SECTION: Template -->
    <div>
        <!-- ANCHOR: MainVisual -->
        <div class="main-visual d-flex justify-center align-center">
            <div
                class="main-visual__body"
                :class="{ 'loading': isVisualLoading }"
            >
                <div class="main-visual__item">
                    <div class="visual-text">
                        <p class="le-heading5">
                            {{ $t('home.section1.texts.text1') }}
                        </p>

                        <p class="le-heading1 mt-3">
                            {{ $t('home.section1.texts.text2') }}
                        </p>

                        <p
                            v-if="this.$route.params.lang != 'eng'"
                            class="le-text-subtitle1 mt-3"
                        >
                            {{ $t('home.section1.texts.text3') }}
                        </p>

                        <p
                            class="le-text-body1 text-center mt-5"
                            v-html="$t('home.section1.texts.text4')"
                        >
                        </p>

                        <div class="mt-10">
                            <v-btn
                                :to="{ name: 'ContactPage' }"
                                color="orange1"
                                depressed
                                :large="$vuetify.breakpoint.smAndUp"
                                class="text-white1"
                            >
                                {{ $t('home.section1.btns.btn1') }}
                            </v-btn>
                        </div>
                    </div>

                    <div class="art-board">
                        <div class="asset asset1">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset1.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset2">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset2.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset2x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset3">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset3.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset3x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset4">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset4.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset4x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset5">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset5.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset6">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset6.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset7">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset7.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset8">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset8.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset8x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset9">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset9.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset9x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset10">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset10.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset10x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset11">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset11.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset11x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset12">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset12.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset12x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset13">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset13.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset14">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset14.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset15">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset15.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset15x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset16">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset16.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset17">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset17.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset17x1.png')"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-visual__arrow">
                <v-img
                    :src="require('@/assets/svg/others/ic_chevrons_down_outline.svg')"
                    alt=""
                    :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 32 : 32)"
                />
            </div>

            <div class="quick-menu-wrap c-contanier d-flex justify-center align-center position-relative h-100">
                <!-- 퀵메뉴 -->
                <div
                    v-if="!$vuetify.breakpoint.smAndDown"
                    class="d-flex flex-column quick-menu gap-3"
                >
                    <v-btn
                        :to="{ name: 'ContactPage' }"
                        color="blue4"
                        depressed
                        class="rounded-pill text-white1 text-left"
                    >
                        <v-icon size="24">
                            fontagon-icons ft-icon ft-ic_questionMark_circle_fill
                        </v-icon>

                        {{ $t('home.section1.btns.btn2') }}
                    </v-btn>

                    <v-btn
                        :to="{ name: 'SampleBadgesPage' }"
                        color="blue3"
                        depressed
                        class="rounded-pill text-white1 text-left"
                    >
                        <v-icon size="24">
                            fontagon-icons ft-icon ft-ic_badge_fill
                        </v-icon>
                        {{ $t('home.section1.btns.btn3') }}
                    </v-btn>

                    <v-btn
                        href="https://www.openbadge-global.com/ns/portal/openbadge/login"
                        target="_blank"
                        color="blue2"
                        depressed
                        class="rounded-pill text-white1 text-left"
                    >
                        <v-icon size="24">
                            fontagon-icons ft-icon ft-ic_wallet_fill
                        </v-icon>
                        {{ $t('home.section1.btns.btn4') }}
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- ANCHOR: 최다 고객사 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <h2
                    class="text-center le-heading3 c-title"
                    v-html="$t('home.section2.texts.text1')"
                >
                </h2>

                <div class="d-flex client-stats">
                    <!-- 도입 고객사 -->
                    <div class="client-stats__column text-center">
                        <div
                            class="circle-obj"
                            :class="{'mb-10': !$vuetify.breakpoint.xsOnly, 'mb-5': $vuetify.breakpoint.xsOnly}"
                        >
                            <v-avatar
                                class="rounded-0"
                                :size="($vuetify.breakpoint.mdAndUp ? 100 : $vuetify.breakpoint.smOnly ? 76 : $vuetify.breakpoint.xsOnly ? 73 : 100)"
                            >
                                <v-img
                                    :src="require('@/assets/img/home-largest-company.png')"
                                    alt=""
                                />
                            </v-avatar>
                        </div>

                        <div class="le-heading4 text-blue3 mb-5">
                            {{ $t('home.section2.texts.text2') }}
                        </div>

                        <div class="le-heading6 mb-3">
                            {{ $t('home.section2.texts.text3') }}
                        </div>
                    </div>

                    <!-- 등록된 배지 -->
                    <div class="client-stats__column text-center">
                        <div
                            class="circle-obj"
                            :class="{'mb-10': !$vuetify.breakpoint.xsOnly, 'mb-5': $vuetify.breakpoint.xsOnly}"
                        >
                            <v-avatar
                                class="rounded-0"
                                :size="($vuetify.breakpoint.mdAndUp ? 100 : $vuetify.breakpoint.smOnly ? 76 : $vuetify.breakpoint.xsOnly ? 73 : 100)"
                            >
                                <v-img
                                    :src="require('@/assets/img/home-largest-certificate.png')"
                                    alt=""
                                />
                            </v-avatar>
                        </div>

                        <div class="le-heading4 text-blue3 mb-5">
                            {{ $t('home.section2.texts.text4') }}
                        </div>

                        <div class="le-heading6 mb-3">
                            {{ $t('home.section2.texts.text5') }}
                        </div>
                    </div>

                    <!-- 발행된 배지 -->
                    <div class="client-stats__column text-center">
                        <div
                            class="circle-obj "
                            :class="{'mb-10': !$vuetify.breakpoint.xsOnly, 'mb-5': $vuetify.breakpoint.xsOnly}"
                        >
                            <v-avatar
                                class="rounded-0"
                                :size="($vuetify.breakpoint.mdAndUp ? 100 : $vuetify.breakpoint.smOnly ? 76 : $vuetify.breakpoint.xsOnly ? 73 : 100)"
                            >
                                <v-img
                                    :src="require('@/assets/img/home-largest-badge.png')"
                                    alt=""
                                />
                            </v-avatar>
                        </div>

                        <div class="le-heading4 text-blue3 mb-5">
                            {{ $t('home.section2.texts.text6') }}
                        </div>

                        <div class="le-heading6 mb-3">
                            {{ $t('home.section2.texts.text7') }}
                        </div>
                    </div>

                    <!-- 지갑 사용자 -->
                    <div class="client-stats__column text-center">
                        <div
                            class="circle-obj"
                            :class="{'mb-10': !$vuetify.breakpoint.xsOnly, 'mb-5': $vuetify.breakpoint.xsOnly}"
                        >
                            <v-avatar
                                class="rounded-0"
                                :size="($vuetify.breakpoint.mdAndUp ? 100 : $vuetify.breakpoint.smOnly ? 76 : $vuetify.breakpoint.xsOnly ? 73 : 100)"
                            >
                                <v-img
                                    :src="require('@/assets/img/home-largest-wallet.png')"
                                    alt=""
                                />
                            </v-avatar>
                        </div>

                        <div class="le-heading4 text-blue3 mb-5">
                            {{ $t('home.section2.texts.text8') }}
                        </div>

                        <div class="le-heading6 mb-3">
                            {{ $t('home.section2.texts.text9') }}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 롤링 배너 -->
        <div 
            class="rbn bg-blue8" 
            :class="{
                'py-16': $vuetify.breakpoint.mdAndUp,
                'py-15': $vuetify.breakpoint.smOnly,
                'py-10': $vuetify.breakpoint.xsOnly,
            }"
        >
            <swiper :options="rbnSwiperOptions">
                <swiper-slide
                    v-for="(image, index) in rbnImages"
                    :key="index"
                >
                    <img
                        :src="image"
                        alt="Banner Image"
                    />
                </swiper-slide>
            </swiper>
        </div>

        <!-- ANCHOR: 높은 고객 만족도 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <h2 class="text-center le-heading3 c-title">
                    {{ $t('home.section3.texts.text1') }}
                </h2>

                <div class="d-flex">
                    <v-row class="d-flex justify-center">
                        <v-col
                            cols="12"
                            md="10"
                        >
                            <v-row class="d-flex justify-center">
                                <!-- 후기 1 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between h-100"
                                        color="blue7"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div>
                                            <div class="mb-5 w-100">
                                                <v-img
                                                    :src="require('@/assets/img/home-quotation-blue.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    alt=""
                                                />
                                            </div>

                                            <p
                                                class="le-text-body1 mb-5"
                                                v-html="$t('home.section3.texts.text2')"
                                            >
                                            </p>
                                        </div>

                                        <div class="d-flex flex-column justify-center align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-high-1.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                                    alt=""
                                                />
                                            </div>

                                            <p class="le-text-subtitle3">
                                                {{ $t('home.section3.texts.text3') }}
                                            </p>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 후기 2 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between h-100"
                                        color="orange2"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div>
                                            <div class="mb-5 w-100">
                                                <v-img
                                                    :src="require('@/assets/img/home-quotation-orange.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    alt=""
                                                />
                                            </div>

                                            <p
                                                class="le-text-body1 mb-5"
                                                v-html="$t('home.section3.texts.text4')"
                                            >
                                            </p>
                                        </div>

                                        <div class="d-flex flex-column justify-center align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-high-2.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smOnly ? 76 : 76)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smOnly ? 76 : 76)"
                                                    alt=""
                                                />
                                            </div>

                                            <p class="le-text-subtitle3">
                                                {{ $t('home.section3.texts.text5') }}
                                            </p>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 후기 3 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between h-100"
                                        color="green1"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div>
                                            <div class="mb-5 w-100">
                                                <v-img
                                                    :src="require('@/assets/img/home-quotation-green.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    alt=""
                                                />
                                            </div>

                                            <p
                                                class="le-text-body1 mb-5"
                                                v-html="$t('home.section3.texts.text6')"
                                            >
                                            </p>
                                        </div>

                                        <div class="d-flex flex-column justify-center align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-high-3.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                                    alt=""
                                                />
                                            </div>

                                            <p class="le-text-subtitle3">
                                                {{ $t('home.section3.texts.text7') }}
                                            </p>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 완벽한 오픈배지 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 mb-5">
                        {{ $t('home.section4.texts.text1') }}
                    </h2>

                    <p class="le-text-subtitle1">
                        {{ $t('home.section4.texts.text2') }}
                    </p>
                </div>

                <div class="d-flex">
                    <v-row class="d-flex justify-center">
                        <v-col
                            cols="12"
                            md="10"
                        >
                            <v-row class="d-flex justify-center">
                                <!-- 발행 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                {{ $t('home.section4.texts.text3') }}
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section4.texts.text4') }}
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section4.texts.text5') }}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-perfect-1.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    :to="{ name: 'IssuancePage' }"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    {{ $t('home.section4.btns.btn1') }}
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 수령 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                {{ $t('home.section4.texts.text6') }}
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section4.texts.text7') }}
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section4.texts.text8') }}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-perfect-2.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    :to="{ name: 'ManagementPage' }"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    {{ $t('home.section4.btns.btn1') }}
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 검증 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                {{ $t('home.section4.texts.text9') }}
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section4.texts.text10') }}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-perfect-3.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    href="https://www.lecos.co.kr/openbadge/verify.html"
                                                    target="_blank"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    {{ $t('home.section4.btns.btn1') }}
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 프리미엄 오픈배지 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 mb-5">
                        {{ $t('home.section5.texts.text1') }}
                    </h2>

                    <p class="le-text-subtitle1">
                        {{ $t('home.section5.texts.text2') }}
                    </p>
                </div>

                <div class="d-flex">
                    <v-row class="d-flex justify-center">
                        <v-col
                            cols="12"
                            md="10"
                        >
                            <v-row class="d-flex justify-center">
                                <!-- 교육 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        color="gray7"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                {{ $t('home.section5.texts.text3') }}
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section5.texts.text4') }}
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section5.texts.text5') }}
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section5.texts.text6') }}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-premium-1.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    :to="{ name: 'EducationPage' }"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    {{ $t('home.section4.btns.btn1') }}
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 기업 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        color="orange2"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                {{ $t('home.section5.texts.text7') }}
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section5.texts.text8') }}
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section5.texts.text9') }}
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section5.texts.text10') }}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-premium-2.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    :to="{ name: 'EnterprisePage' }"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    {{ $t('home.section4.btns.btn1') }}
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 기관 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        color="blue6"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                {{ $t('home.section5.texts.text11') }}
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section5.texts.text12') }}
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section5.texts.text13') }}
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    {{ $t('home.section5.texts.text14') }}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-premium-3.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    :to="{ name: 'AssociationPage' }"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    {{ $t('home.section4.btns.btn1') }}
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 오픈배지의 모든 것 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <div class="c-title text-center">
                    <h2
                        class="text-center le-heading3 mb-5"
                        v-html="$t('home.section6.texts.text1')"
                    >
                    </h2>
                </div>

                <div class="d-flex">
                    <v-row class="d-flex justify-center">
                        <v-col
                            cols="12"
                            md="10"
                        >
                            <v-row class="d-flex justify-center">
                                <!-- 편리함 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center w-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="mb-5">
                                            <v-img
                                                :src="require('@/assets/img/home-all-1.png')"
                                                :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                alt=""
                                            />
                                        </div>

                                        <p class="le-heading4 mb-5">
                                            {{ $t('home.section6.texts.text2') }}
                                        </p>

                                        <div
                                            class="le-text-body1 text-center"
                                            v-html="$t('home.section6.texts.text3')"
                                        >
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 신속함 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center w-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="mb-5">
                                            <v-img
                                                :src="require('@/assets/img/home-all-2.png')"
                                                :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                alt=""
                                            />
                                        </div>

                                        <p class="le-heading4 mb-5">
                                            {{ $t('home.section6.texts.text4') }}
                                        </p>

                                        <div
                                            class="le-text-body1 text-center"
                                            v-html="$t('home.section6.texts.text5')"
                                        >
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 전문성 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center w-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="mb-5">
                                            <v-img
                                                :src="require('@/assets/img/home-all-3.png')"
                                                :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                alt=""
                                            />
                                        </div>

                                        <p class="le-heading4 mb-5">
                                            {{ $t('home.section6.texts.text6') }}
                                        </p>

                                        <div
                                            class="le-text-body1 text-center"
                                            v-html="$t('home.section6.texts.text7')"
                                        >
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 메인 공통 공지 모달 -->
        <NewYearModal2025 :dialog="false" />
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getHomeMetaInfo } from '@/utils/metaInfo.js';
// https://github.surmon.me/vue-awesome-swiper
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { isMobile, isTablet } from 'mobile-device-detect';
import NewYearModal2025 from '@/components/modal/NewYearModal2025';

export default {
    name: 'HomePage',
    components: {
        Swiper,
        SwiperSlide,
        NewYearModal2025
    },

    // SECTION: Meta Information
    metaInfo() {
        return getHomeMetaInfo(this);
    },
    // !SECTION: Meta Information

    // SECTION: Data Properties
    data: function () {
        return {
            isMobile: isMobile,
            isTablet: isTablet,
            isVisualLoading: true,
            rbnSwiperOptions: {
                loop : true, // 슬라이드 반복 여부
                loopAdditionalSlides : 1,
                autoplay: {
                    delay: 0, // 자동 재생 지연 시간 (0으로 설정하여 계속 스크롤)
                    disableOnInteraction: false // 사용자 상호작용에도 자동 재생 계속
                },
                // slidesPerView: '7', // 화면에 보이는 슬라이드 수 자동 조정
                spaceBetween: 40, // 슬라이드 사이의 간격
                speed: 9000, // 스크롤 속도 (밀리초 단위)
                freemode: false,

                breakpoints: {
                    0: { // 모바일 뷰포트
                        // spaceBetween: 40,
                        slidesPerView: 3
                    },
                    600: { // 태블릿 뷰포트
                        slidesPerView: 5
                    },
                    960: { // 데스크탑 뷰포트
                        slidesPerView: 7
                    },
                    1400: { // 데스크탑 뷰포트
                        slidesPerView: 11
                    }
                }
            },
            rbnImages: [
                require('@/assets/img/home-rbn-1.png'),
                require('@/assets/img/home-rbn-2.png'),
                require('@/assets/img/home-rbn-3.png'),

                require('@/assets/img/home-rbn-4.png'),
                require('@/assets/img/home-rbn-5.png'),
                require('@/assets/img/home-rbn-6.png'),

                require('@/assets/img/home-rbn-7.png'),
                require('@/assets/img/home-rbn-8.png'),
                require('@/assets/img/home-rbn-9.png'),

                require('@/assets/img/home-rbn-10.png'),
                require('@/assets/img/home-rbn-11.png'),
                require('@/assets/img/home-rbn-12.png'),
            ],
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    computed: {
        //
    },

    watch: {
        //
    },

    created() {
        //
    },

    mounted() {
        this.isVisualLoading = false;
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        //
    },
    // !SECTION: Methods
};
</script>
