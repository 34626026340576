var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-page"},[_c('PageTitle',{attrs:{"main-title":this.$t('issuance.section1.texts.text1-1'),"sub-title":this.$t('issuance.section1.texts.text1-2'),"bg-color":"blue9"}}),_c('section',{staticClass:"c-section bg-blue9 d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('div',{staticClass:"d-flex flex-column step-wrap",class:{ 'gap-12': _vm.$vuetify.breakpoint.mdAndUp, 'gap-10': _vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 01 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/issuance-step-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" "+_vm._s(this.$t('issuance.section1.texts.text2-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('issuance.section1.texts.text2-2'))+" ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 02 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/issuance-step-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" "+_vm._s(this.$t('issuance.section1.texts.text3-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('issuance.section1.texts.text3-2'))+" ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 03 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/issuance-step-3.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" "+_vm._s(this.$t('issuance.section1.texts.text4-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('issuance.section1.texts.text4-2'))+" ")]),_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('issuance.section1.texts.text4-3'))+" ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 04 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/issuance-step-4.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" "+_vm._s(this.$t('issuance.section1.texts.text5-1'))+" ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('issuance.section1.texts.text5-2'))+" ")]),_c('p',{staticClass:"le-text-body1"},[_vm._v(" "+_vm._s(this.$t('issuance.section1.texts.text5-3'))+" ")])])])])],1)])])]),_c('section',{staticClass:"c-section d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('h2',{staticClass:"text-center le-heading3 c-title"},[_vm._v(" "+_vm._s(_vm.$t('issuance.section2.texts.text1-1'))+" ")]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"text-white1",attrs:{"to":{ name: 'ContactPage' },"color":"orange1","depressed":"","large":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(" "+_vm._s(_vm.$t('issuance.section2.btns.btn1'))+" ")])],1),_c('div',{staticClass:"d-flex justify-center flex-wrap mx-auto",class:{
                    'gap-12 mt-20': _vm.$vuetify.breakpoint.mdAndUp,
                    'gap-10 mt-10': _vm.$vuetify.breakpoint.smAndDown
                },staticStyle:{"max-width":"995px"}},[_c('v-card',{class:{ 'pa-12': _vm.$vuetify.breakpoint.mdAndUp, 'pa-9': _vm.$vuetify.breakpoint.smOnly, 'pa-7': _vm.$vuetify.breakpoint.xsOnly },style:({
                        'width': _vm.$vuetify.breakpoint.mdAndUp ? '470px' : _vm.$vuetify.breakpoint.smOnly ? '380px' : '100%',
                        'max-width': _vm.$vuetify.breakpoint.xsOnly ? '360px' : ''
                    }),attrs:{"color":"blue6"}},[_c('div',{staticClass:"d-flex"},[_c('div',{class:{ 'mr-10': _vm.$vuetify.breakpoint.mdAndUp, 'mr-8': _vm.$vuetify.breakpoint.smAndDown }},[_c('p',{staticClass:"le-heading5"},[_vm._v(" "+_vm._s(_vm.$t('issuance.section2.texts.text2-1'))+" ")]),_c('v-img',{attrs:{"src":require('@/assets/img/issuance-cost-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 136 : (_vm.$vuetify.breakpoint.smAndDown ? 76 : 76),"height":_vm.$vuetify.breakpoint.mdAndUp ? 136 : (_vm.$vuetify.breakpoint.smAndDown ? 76 : 76),"alt":""}})],1),_c('div',{staticClass:"le-text-body1 mt-1",domProps:{"innerHTML":_vm._s(_vm.$t('issuance.section2.texts.text2-2'))}})])]),_c('v-card',{class:{ 'pa-12': _vm.$vuetify.breakpoint.mdAndUp, 'pa-9': _vm.$vuetify.breakpoint.smOnly, 'pa-7': _vm.$vuetify.breakpoint.xsOnly },style:({
                        'width': _vm.$vuetify.breakpoint.mdAndUp ? '470px' : _vm.$vuetify.breakpoint.smOnly ? '380px' : '100%',
                        'max-width': _vm.$vuetify.breakpoint.xsOnly ? '360px' : ''
                    }),attrs:{"color":"orange2"}},[_c('div',{staticClass:"d-flex"},[_c('div',{class:{ 'mr-10': _vm.$vuetify.breakpoint.mdAndUp, 'mr-8': _vm.$vuetify.breakpoint.smAndDown }},[_c('p',{staticClass:"le-heading5 white-space-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('issuance.section2.texts.text3-1'))+" ")]),_c('v-img',{attrs:{"src":require('@/assets/img/issuance-cost-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 136 : (_vm.$vuetify.breakpoint.smAndDown ? 76 : 76),"height":_vm.$vuetify.breakpoint.mdAndUp ? 136 : (_vm.$vuetify.breakpoint.smAndDown ? 76 : 76),"alt":""}})],1),_c('div',{staticClass:"le-text-body1 mt-1",domProps:{"innerHTML":_vm._s(_vm.$t('issuance.section2.texts.text3-2'))}})])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }